<template>
  <div class="base-content">
    <query-frame
      :page-visible="false"
      :add-visible="false"
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称" v-if="isStoreUser">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="发车时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->
      <div class="query__summary" slot="summary">
        <!--<div class="query__summary__group">-->
        <!--<div class="query__summary&#45;&#45;label">收入：</div>-->
        <!--<div class="query__summary&#45;&#45;value">888 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
        <!--<div class="query__summary&#45;&#45;label">退款：</div>-->
        <!--<div class="query__summary&#45;&#45;value">0 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
        <!--<div class="query__summary&#45;&#45;label">充值：</div>-->
        <!--<div class="query__summary&#45;&#45;value">368 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
        <!--<div class="query__summary&#45;&#45;label">订单：</div>-->
        <!--<div class="query__summary&#45;&#45;value">30 个</div>-->
        <!--</div>-->
        <div style="display: flex;flex-direction: row">
          <div class="query__summary__group primary-color">
            <div class="query__summary--label">总发车次数：</div>
            <div class="query__summary--value">{{totalCount}} 次</div>
          </div>
          <div class="query__summary__group primary-color">
            <div class="query__summary--label">总有效开单数：</div>
            <div class="query__summary--value">{{orderTotalCount}} 笔</div>
          </div>
        </div>
      </div>
      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import {vehicleUseStatistics, orderTotalCount} from '@/api/vehicle';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '../../components/IDatePicker.vue';
import moment from 'moment';

export default {
  name: 'VehicleStatistics',
  mixins: [queryMixin],
  components: {
    IDatePicker
  },
  props: {},
  data() {
    const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');
    return {
      query: {
        shopId: null,
        duration: [startDate, endDate],
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '车辆名称',
          dataIndex: 'cardName',
          key: 'cardName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '发车次数',
          dataIndex: 'allCount',
          key: 'allCount',
          width: 120
        },
        {
          title: '总时间(秒)',
          dataIndex: 'dayTotalTime',
          key: 'dayTotalTime',
          width: 120
        },
        {
          title: '总圈数',
          dataIndex: 'dayTotalLap',
          key: 'dayTotalLap',
          width: 120
        },
      ],
      dataList: [],
      totalCount: 0,
      orderTotalCount: 0,
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;
      if (this.params.duration && this.params.duration.length) {
        this.params.startTime = this.params.duration[0];
        this.params.endTime = this.params.duration[1];
      }
      delete this.params.duration;
      this.dataList = [];
      vehicleUseStatistics(this.params).then((data) => {
        this.dataList = data;
        this.totalCount = data.reduce((acc, obj) => {
          return acc + obj.allCount;
        }, 0);
      }).catch((res) => {
        console.log(res);
      });
      orderTotalCount(this.params).then((count) => {
        this.orderTotalCount = count;
      }).catch((res) => {
        console.log(res);
      });
    },

    handleReset() {
      const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');
      const duration = [startDate, endDate];
      this.query = {};
      setTimeout(() => {
        this.query = {
          duration
        };
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
